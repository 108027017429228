import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Box, Dialog } from '@mui/material';
import { ModalContent } from './ModalContent';
import { Loader } from 'shared/ui/Loader';

type ModalProps = {
  children: ReactNode;
  isModalOpen: boolean;
  handleCloseModal: () => void;
  handleSubmitModal: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  approveNameButton: string;
};

export const Modal = ({
  children,
  isModalOpen,
  handleCloseModal,
  handleSubmitModal,
  disabled,
  approveNameButton,
  isLoading,
}: ModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isModalOpen} onClose={handleCloseModal}>
      <ModalContent
        onClose={handleCloseModal}
        buttons={
          <Box display="flex" justifyContent="flex-end" width="100%" gap="10px">
            <Button onClick={handleCloseModal}>{t('Cancel')}</Button>
            <Button
              startIcon={isLoading && <Loader color="inherit" />}
              onClick={handleSubmitModal}
              variant="contained"
              color="primary"
              disabled={disabled}
            >
              {approveNameButton}
            </Button>
          </Box>
        }
      >
        {children}
      </ModalContent>
    </Dialog>
  );
};
